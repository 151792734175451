//src\Payment\Domain\ValueObject\Currency.php
const dataCurrency = [
  {
    index: 0,
    id: "PLN",
    value: "PLN",
    text: "PLN",
    name: "Polski Złoty",
  },
  {
    index: 1,
    id: "EUR",
    value: "EUR",
    text: "EUR",
    name: "Euro",
  },
  {
    index: 2,
    id: "USD",
    value: "USD",
    text: "USD",
    name: "Dolar Amerykański",
  },
  {
    index: 3,
    id: "GBP",
    value: "GBP",
    text: "GBP",
    name: "Funt Brytyjski",
  },
  {
    index: 4,
    id: "CHF",
    value: "CHF",
    text: "CHF",
    name: "Frank Szwajcarski",
  },
  {
    index: 5,
    id: "CAD",
    value: "CAD",
    text: "CAD",
    name: "Dolar Kanadyjski",
  },
  {
    index: 6,
    id: "JPY",
    value: "JPY",
    text: "JPY",
    name: "Jen Japoński",
  },
  {
    index: 7,
    id: "AUD",
    value: "AUD",
    text: "AUD",
    name: "Dolar Australijski",
  },
  {
    index: 8,
    id: "CZK",
    value: "CZK",
    text: "CZK",
    name: "Korona Czeska",
  },
  {
    index: 9,
    id: "HRK",
    value: "HRK",
    text: "HRK",
    name: "Kuna Chorwacka",
  },
  {
    index: 10,
    id: "DKK",
    value: "DKK",
    text: "DKK",
    name: "Korona Duńska",
  },
  {
    index: 11,
    id: "NOK",
    value: "NOK",
    text: "NOK",
    name: "Korona Norweska",
  },
  {
    index: 12,
    id: "RUB",
    value: "RUB",
    text: "RUB",
    name: "Rubel Rosyjski",
  },
  {
    index: 13,
    id: "SEK",
    value: "SEK",
    text: "SEK",
    name: "Korona Szwedzka",
  },
  {
    index: 14,
    id: "HUF",
    value: "HUF",
    text: "HUF",
    name: "Forint Węgierski",
  },
  {
    index: 15,
    id: "AED",
    value: "AED",
    text: "AED",
    name: "Dirham",
  },
  {
    index: 16,
    id: "KZT",
    value: "KZT",
    text: "KZT",
    name: "Tenge",
  },
];
export { dataCurrency };
