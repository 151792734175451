<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Preloader from "@/components/preloader";
import Switches from "vue-switches";
import AddressFormGroup from "@/components/form-elements/address-form-group";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pl";
import Multiselect from "vue-multiselect";
import vue2Dropzone from "vue2-dropzone";
import {
  required,
  maxLength,
  minLength,
  numeric,
  helpers,
} from "vuelidate/lib/validators";
import Repository from "@/app/repository/repository-factory";
import Client from "@/app/repository/axios-client";
import { dataCurrency } from "@/data/data-currency-list";
import { dataTypeOfBankAccounts } from "@/data/data-type-of-bank-accounts";
import { roleService } from "@/app/service/roleService";

const swiftregex = helpers.regex(
  "swiftregex",
  /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/
);

const alphaCapNum = helpers.regex(
  "alphaCapNum",
  /^[A-Z0-9]*$/
);

const PaymentBankAccountRepository = Repository.get(
  "PaymentBankAccountRepository"
);
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");
const PaymentBankRepository = Repository.get("PaymentBankRepository");
const AttachmentRepository = Repository.get("AttachmentRepository");

/**
 * Dodawanie rachunku bankowego
 */
export default {
  page: {
    title: "Dodawanie rachunku bankowego",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Preloader, Multiselect, DatePicker, Switches, vueDropzone: vue2Dropzone, AddressFormGroup },
  data() {
    return {
      header: {
        title: "Dodaj rachunek bankowy",
        items: [
          {
            text: "Strona główna",
            to: {
              name: "home",
            },
          },
          {
            text: "Lista rachunków bankowych",
            to: {
              name: "Lista rachunków bankowych",
            },
          },
          {
            text: "Dodaj rachunek bankowy",
          },
        ],
      },
      dropzoneOptions: {
        url: AttachmentRepository.getResourcePath(),
        thumbnailWidth: 150,
        paramName: "file",
        maxFilesize: 10, // 10MB
        addRemoveLinks: true,
        dictRemoveFile: "✕",
        headers: {
          Authorization: Client.defaults.headers.common["Authorization"],
          "Cache-Control": null,
          "X-Requested-With": null,
        },
      },
      form: {
        companyId: "",
        bankId: null,
        bankAccountName: "",
        bankAccountNumber: "",
        typeOfBankAccount: null,
        swift: "",
        currency: null,
        departmentName: "",
        departmentAddress: "",
        departmentAddressCity: "",
        departmentAddressStreet: "",
        departmentAddressHouseNo: "",
        departmentAddressFlatNo: "",
        departmentAddressPostalCode: "",
        departmentAddressInternational: "",
        iban: "",
        openDate: null,
        closeDate: null,
        additionalInfo: "",
        isAvailableForTransfer: true,
        isDefault: false,
        isDefaultPrepaidCard: false,
        isActive: false,
        attachments: [],
      },
      preloader: true,
      disabledBtn: false,
      dataCurrency: dataCurrency,
      dataTypeOfBankAccounts: dataTypeOfBankAccounts,
      companySelectOptions: [],
      bankSelectOptions: [],
    };
  },
  validations: {
    form: {
      bankAccountNumber: {
        required,
        // maxLength: maxLength(26),
        // minLength: minLength(26),
        // numeric,
        maxLength (value) {
          return maxLength(this.isCountryCodeOtherThanPL ? 50 : 26)(value)
        },
        minLength (value) {
          return minLength(this.isCountryCodeOtherThanPL ? 6 : 26)(value)
        },
        numeric (value) {
          if (!this.isCountryCodeOtherThanPL) {
            return numeric(value)
          }
          return true
        },
        alphaCapNum (value) {
          const regex = /^[A-Z0-9/]*$/;
          if (this.isCountryCodeOtherThanPL) {
            return regex.test(value)
          }
          return true
        },
      },
      companyId: {
        required,
      },
      bankId: {
        required,
      },
      swift: {
        required,
        swiftregex,
        maxLength: maxLength(11),
        minLength: minLength(8),
      },
      currency: {
        required,
      },
      typeOfBankAccount: {
        required,
      },
      bankAccountName: {
        maxLength: maxLength(255),
        minLength: minLength(2),
        required,
      },
      departmentName: {
        maxLength: maxLength(255),
        minLength: minLength(2),
        required,
      },
      // departmentAddress: {
      //   maxLength: maxLength(255),
      //   minLength: minLength(2),
      //   required,
      // },
      iban: {
        required,
        maxLength: maxLength(34),
        minLength: minLength(5),
      },
      openDate: {
        required: false,
      },
      closeDate: {
        required: false,
      },
      additionalInfo: {
        maxLength: maxLength(500),
        required: false,
      },
      attachments: [],
    },
  },
  created() {
    this.preloader = true;
    this.getData();
  },
  methods: {
    async getCompanies() {
      try {
        const { data } = await PaymentCompanyRepository.getAll();
        this.companySelectOptions = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getBanks() {
      try {
        const { data } = await PaymentBankRepository.getAll();
        this.bankSelectOptions = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        await Promise.all([this.getCompanies(), this.getBanks()]);
        this.preloader = false;
      } catch (error) {
        console.log(error);
      }
    },
    addBankAccount() {
      this.formSubmit();

      if (this.$v.$error === true) {
        return false;
      }

      let payload = Object.assign({}, this.form);

      payload.typeOfBankAccount = this.form.typeOfBankAccount.id;
      payload.currency = this.form.currency.id;
      payload.bankId = this.form.bankId.bankId;
      payload.companyId = this.form.companyId.companyId;

      this.disabledBtn = true;
      this.preloader = true;

      PaymentBankAccountRepository.create(payload)
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Rachunek bankowy został dodany!",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({
                name: "Lista rachunków bankowych",
              });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    formSubmit(e) {
      this.$v.$touch();
      this.$refs.AddressFormGroup.$v.$touch();
    },
    currencyLabel({ id, index, name }) {
      return `${id} ${index} ${name}`;
    },
    typeOfAccountLabel({ name, description }) {
      return `${name} ${description}`;
    },
    companyLabel({ name, nip, address, companyId }) {
      return `${name} ${nip} ${address} ${companyId}`;
    },
    bankLabel({ name, shortName, bankId }) {
      return `${name} ${shortName} ${bankId}`;
    },
    dropzoneAfterSuccess(file, response) {
      this.form.attachments.push(response.uuid);
      file["tempUploadUuid"] = response.uuid;
    },
    dropzoneAfterRemove(file, error, xhr) {
      for (let i = 0; i < this.form.attachments.length; i++) {
        if (!this.form.attachments.attachmentId) {
          if (
            file.tempUploadUuid &&
            this.form.attachments[i] === file.tempUploadUuid
          ) {
            this.form.attachments.splice(i, 1);
          }
        }
      }
    },
    dropzoneSendingEvent(file, xhr, formData) {
      formData.append("attachmentTypeOfObject", 2);
    },
    dispatchSwiftAction(el) {
      this.form.swift = el.swift;
    },
    dispatchTypeOfBankAccountAction(el) {
      this.form.bankAccountName = el.name;
    },
    getDepartmentAddressCityFormValues: function (params) {
      this.form.departmentAddressCity = params;
    },
    getDepartmentAddressStreetFormValues: function (params) {
      this.form.departmentAddressStreet = params;
    },
    getDepartmentAddressHouseNoFormValues: function (params) {
      this.form.departmentAddressHouseNo = params;
    },
    getDepartmentAddressFlatNoFormValues: function (params) {
      this.form.departmentAddressFlatNo = params;
    },
    getDepartmentAddressPostalCodeFormValues: function (params) {
      this.form.departmentAddressPostalCode = params;
    },
    getDepartmentAddressInternationalFormValues: function (params) {
      this.form.departmentAddressInternational = params;
    },
    supertrim(inp) {
      this.form.bankAccountNumber = inp.replace(/\s/g, '');
      this.$v.form.bankAccountNumber.$touch()
    }
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
    isProductAdministrator() {
      return roleService.isProductAdministrator();
    },
    isFinancialOperator() {
      return roleService.isFinancialOperator();
    },
    isCountryCodeOtherThanPL() {
      if (this.form.bankId) {
        return this.form.bankId.countryCode != 'PL' ? true : false;
      }
      return false;
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="header.title" :items="header.items" />

    <b-form @submit.prevent="formSubmit" v-if="isAdmin || isProductAdministrator">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-sm">
            <b-card-header>
              <div class="row align-items-center">
                <div class="col">Formularz dodawania rachunku bankowego</div>
                <div class="col text-right">
                  <b-link class="btn btn-sm btn-warning" :to="{ name: 'Lista rachunków bankowych' }">Anuluj</b-link>
                </div>
              </div>
            </b-card-header>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-5">

                  <el-divider>Dane rachunku</el-divider>

                  <div class="form-group">
                    <label for="company">
                      Wybierz firmę
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="company" v-model.trim="form.companyId" :options="companySelectOptions" :custom-label="companyLabel" :class="{ 'is-invalid': $v.form.companyId.$error, 'is-valid': !$v.form.companyId.$invalid && !$v.form.companyId.$error }" track-by="companyId" name="company" placeholder="Wybierz firmę z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small">{{ props.option.address }} / {{ props.option.sourceOfData ? props.option.sourceOfData.name : "" }} {{ props.option.businessUnitCode }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.address }} / {{ props.option.sourceOfData ? props.option.sourceOfData.name : "" }} {{ props.option.businessUnitCode }}</span>
                        </div>
                      </template>
                     <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <div class="form-group">
                    <label for="bankId">
                      Wybierz bank
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="bankId" @select="dispatchSwiftAction" v-model.trim="form.bankId" :options="bankSelectOptions" :custom-label="bankLabel" :class="{ 'is-invalid': $v.form.bankId.$error, 'is-valid': !$v.form.bankId.$invalid && !$v.form.bankId.$error }" track-by="bankId" name="bankId" placeholder="Wybierz bank z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small">{{ props.option.address }} / {{ props.option.shortName }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.address }} / {{ props.option.shortName }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <div class="form-group">
                    <label for="swift">
                      BIC / SWIFT
                      <em class="text-danger">*</em>
                    </label>
                    <input id="swift" v-model.trim="form.swift" @input="$v.form.swift.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.swift.$error, 'is-valid': !$v.form.swift.$invalid && !$v.form.swift.$error }" type="text" name="swift" placeholder="Wpisz BIC / SWIFT" value required />
                    <div v-if="$v.form.swift.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.swift.$error && !$v.form.swift.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.swift.$error && !$v.form.swift.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.swift.$error && !$v.form.swift.minLength">Zbyt mała ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.swift.$error && !$v.form.swift.swiftregex">Wpisano niedozwolone znaki!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="currency">
                      Wybierz walutę
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="currency" v-model.trim="form.currency" :options="dataCurrency" :custom-label="currencyLabel" :class="{ 'is-invalid': $v.form.currency.$error, 'is-valid': !$v.form.currency.$invalid && !$v.form.currency.$error }" track-by="id" name="currency" placeholder="Wybierz walutę z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.name }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <div class="form-group">
                    <label for="typeOfBankAccount">
                      Wybierz typ rachunku
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="typeOfBankAccount" @select="dispatchTypeOfBankAccountAction" v-model.trim="form.typeOfBankAccount" :options="dataTypeOfBankAccounts" :custom-label="typeOfAccountLabel" :class="{ 'is-invalid': $v.form.typeOfBankAccount.$error, 'is-valid': !$v.form.typeOfBankAccount.$invalid && !$v.form.typeOfBankAccount.$error }" track-by="id" name="typeOfBankAccount" placeholder="Wybierz typ rachunku z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.description }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <div class="form-group">
                    <label for="openDate">
                      Data otwarcia rachunku
                    </label>
                    <date-picker id="openDate" v-model="form.openDate" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" @input="$v.form.openDate.$touch()" :class="{ 'is-invalid': $v.form.openDate.$error, 'is-valid': !$v.form.openDate.$invalid && !$v.form.openDate.$error }" lang="pl" placeholder="Data otwarcia rachunku YYYY-MM-DD" />
                  </div>

                  <div class="form-group">
                    <label for="closeDate">
                      Data zamknięcia rachunku
                    </label>
                    <date-picker id="closeDate" v-model="form.closeDate" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" @input="$v.form.closeDate.$touch()" :class="{ 'is-invalid': $v.form.closeDate.$error, 'is-valid': !$v.form.closeDate.$invalid && !$v.form.closeDate.$error }" lang="pl" placeholder="Data zamknięcia rachunku YYYY-MM-DD" />
                  </div>

                  <div class="form-group">
                    <label for="transferAdditionalInfoField">Uwagi</label>
                    <textarea id="transferVatAmountField" v-model.trim="form.additionalInfo" @input="$v.form.additionalInfo.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.additionalInfo.$error, 'is-valid': !$v.form.additionalInfo.$invalid && !$v.form.additionalInfo.$error }" type="text" placeholder="Wpisz dodatkowe uwagi" value></textarea>
                    <div v-if="$v.form.additionalInfo.$invalid" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.additionalInfo.$invalid && !$v.form.additionalInfo.maxLength">Przekroczona dozwolona ilość znaków!</span>
                    </div>
                  </div>

                </div>
                <div class="col-md-5 offset-md-1">

                  <el-divider>Dane oddziału</el-divider>

                  <div class="form-group">
                    <label for="departmentName">
                      Nazwa oddziału banku prowadzącego rachunek <em class="text-danger">*</em>
                    </label>
                    <input id="departmentName" v-model.trim="form.departmentName" @input="$v.form.departmentName.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.departmentName.$error, 'is-valid': !$v.form.departmentName.$invalid && !$v.form.departmentName.$error }" type="text" name="departmentName" placeholder="Wpisz nazwę oddziału banku prowadzącego rachunek" value />
                    <div v-if="$v.form.departmentName.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.departmentName.$error && !$v.form.departmentName.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.departmentName.$error && !$v.form.departmentName.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.departmentName.$error && !$v.form.departmentName.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div>

                  <!-- <div class="form-group">
                    <label for="departmentAddress">
                      Adres oddziału banku prowadzącego rachunek <em class="text-danger">*</em>
                    </label>
                    <input id="departmentAddress" v-model.trim="form.departmentAddress" @input="$v.form.departmentAddress.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.departmentAddress.$error, 'is-valid': !$v.form.departmentAddress.$invalid && !$v.form.departmentAddress.$error }" type="text" name="departmentAddress" placeholder="Wpisz adres oddziału banku prowadzącego rachunek" value />
                    <div v-if="$v.form.departmentAddress.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.departmentAddress.$error && !$v.form.departmentAddress.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.departmentAddress.$error && !$v.form.departmentAddress.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.departmentAddress.$error && !$v.form.departmentAddress.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div> -->

                  <AddressFormGroup @address-city-form-group="getDepartmentAddressCityFormValues" @address-street-form-group="getDepartmentAddressStreetFormValues" @address-house-no-form-group="getDepartmentAddressHouseNoFormValues" @address-flat-no-form-group="getDepartmentAddressFlatNoFormValues" @address-postal-code-form-group="getDepartmentAddressPostalCodeFormValues" @address-international-form-group="getDepartmentAddressInternationalFormValues" :incomingAddressCityValue="form.departmentAddressCity" :incomingAddressStreetValue="form.departmentAddressStreet" :incomingAddressHouseNoValue="form.departmentAddressHouseNo" :incomingAddressFlatNoValue="form.departmentAddressFlatNo" :incomingAddressPostalCodeValue="form.departmentAddressPostalCode" :incomingAddressInternationalValue="form.departmentAddressInternational" name="AddressFormGroup" ref="AddressFormGroup" />

                  <el-divider class="mt-5">Pozostałe dane</el-divider>

                  <div class="form-group">
                    <label for="bankAccountName">
                      Nazwa rachunku bankowego
                      <em class="text-danger">*</em>
                    </label>
                    <input id="bankAccountName" v-model.trim="form.bankAccountName" @input="$v.form.bankAccountName.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.bankAccountName.$error, 'is-valid': !$v.form.bankAccountName.$invalid && !$v.form.bankAccountName.$error }" type="text" name="bankAccountName" placeholder="Wpisz nazwę rachunku bankowego" value required />
                    <div v-if="$v.form.bankAccountName.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.bankAccountName.$error && !$v.form.bankAccountName.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.bankAccountName.$error && !$v.form.bankAccountName.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.bankAccountName.$error && !$v.form.bankAccountName.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="providerAccountField">
                      Numer rachunku firmy
                      <em class="text-danger">*</em>
                    </label>
                    <input id="providerAccountField" pattern="^\S+$" @keydown.space.prevent v-model.trim="form.bankAccountNumber" @input="supertrim(form.bankAccountNumber)" class="form-control" :class="{ 'is-invalid': $v.form.bankAccountNumber.$error, 'is-valid': !$v.form.bankAccountNumber.$invalid && !$v.form.bankAccountNumber.$error }" type="text" name="providerAccountField" placeholder="Wpisz numer rachunku firmy" value required />
                    <div v-if="$v.form.bankAccountNumber.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.minLength">Zbyt mała ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.numeric">Pole przyjmuje wyłącznie wartości liczbowe!</span>
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.alphaCapNum">Pole przyjmuje wyłącznie wartości liczbowe oraz duże litery bez polskich znaków!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="iban">
                      IBAN rachunku firmy
                      <em class="text-danger">*</em>
                    </label>
                    <input id="iban" v-model.trim="form.iban" @input="$v.form.iban.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.iban.$error, 'is-valid': !$v.form.iban.$invalid && !$v.form.iban.$error }" type="text" name="iban" placeholder="Wpisz IBAN rachunku firmy" value required />
                    <div v-if="$v.form.iban.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.iban.$error && !$v.form.iban.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.iban.$error && !$v.form.iban.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.iban.$error && !$v.form.iban.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div>

                  <el-divider class="mt-5">Status</el-divider>

                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.isAvailableForTransfer" type-bold="false" :color="form.isAvailableForTransfer == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">Możliwość wnioskowania o przelew z rachunku:</span>
                      <span v-if="form.isAvailableForTransfer" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>

                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.isDefault" type-bold="false" :color="form.isDefault == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">Rachunek domyślny:</span>
                      <span v-if="form.isDefault" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>

                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.isDefaultPrepaidCard" type-bold="false" :color="form.isDefaultPrepaidCard == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">Rachunek domyślny dla kart:</span>
                      <span v-if="form.isDefaultPrepaidCard" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>

                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.isActive" type-bold="false" :color="form.isActive == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">Rachunek aktywny w systemie:</span>
                      <span v-if="form.isActive" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-11">
                  <div class="form-group">
                    <label for="dropzone">Załączniki</label>
                    <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" @vdropzone-success="dropzoneAfterSuccess" @vdropzone-sending="dropzoneSendingEvent" @vdropzone-removed-file="dropzoneAfterRemove" :useCustomSlot=true >
                      <div class="dropzone-custom-content dz-message">
                        upuść pliki tutaj lub kliknij, aby przesłać
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
                <div class="col-md-12 offset-md-1 mt-3">
                  <button class="btn btn-dark" v-on:click="addBankAccount" :disabled="$v.form.$invalid || this.$refs.AddressFormGroup.$v.$invalid || disabledBtn" type="submit">Dodaj rachunek bankowy</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Layout>
</template>