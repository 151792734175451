//src\Payment\Domain\ValueObject\TypeOfBankAccount.php
const dataTypeOfBankAccounts = [
  {
    id: 1,
    index: 1,
    name: "BIEŻĄCY",
    value: "BIEŻĄCY",
    text: "BIEŻĄCY",
    description: "Rachunek bieżący",
  },
  {
    id: 2,
    index: 2,
    name: "ZFŚS",
    value: "ZFŚS",
    text: "ZFŚS",
    description: "Zakładowy Fundusz Świadczeń Socjalnych",
  },
  {
    id: 3,
    index: 3,
    name: "ZFRON",
    value: "ZFRON",
    text: "ZFRON",
    description: "Zakładowy Fundusz Rehabilitacji Osób niepełnosprawnych",
  },
  {
    id: 4,
    index: 4,
    name: "WALUTOWY",
    value: "WALUTOWY",
    text: "WALUTOWY",
    description: "Rachunek walutowy",
  },
  {
    id: 5,
    index: 5,
    name: "POMOCNICZY",
    value: "POMOCNICZY",
    text: "POMOCNICZY",
    description: "Rachunek pomocniczy",
  },
  {
    id: 6,
    index: 6,
    name: "PŁACOWY",
    value: "PŁACOWY",
    text: "PŁACOWY",
    description: "Rachunek płacowy",
  },
  {
    id: 7,
    index: 7,
    name: "VAT",
    value: "VAT",
    text: "VAT",
    description: "Rachunek VAT",
  },
  {
    id: 8,
    index: 8,
    name: "LOKATA",
    value: "LOKATA",
    text: "LOKATA",
    description: "Rachunek lokacyjny",
  },
  {
    id: 9,
    index: 9,
    name: "OSZCZĘDNOŚCIOWY",
    value: "OSZCZĘDNOŚCIOWY",
    text: "OSZCZĘDNOŚCIOWY",
    description: "Rachunek oszczędnościowy",
  },
  {
    id: 10,
    index: 10,
    name: "TECHNICZNY",
    value: "TECHNICZNY",
    text: "TECHNICZNY",
    description: "Rachunek techniczny",
  },
  {
    id: 11,
    index: 11,
    name: "MAKLERSKI",
    value: "MAKLERSKI",
    text: "MAKLERSKI",
    description: "Rachunek maklerski",
  },
];

const dataTypeOfBoolean = [
  {
    id: 1,
    name: "TAK",
    value: 1,
    bool: true,
    text: "TAK",
    description: "Tak",
  },
  {
    id: 2,
    name: "NIE",
    value: 0,
    bool: false,
    text: "NIE",
    description: "Nie",
  },
];

export { dataTypeOfBoolean }
export { dataTypeOfBankAccounts };
